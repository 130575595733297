// This is the entryfile that is being compiled with vite-rails
// to be able to use hotwire

import '@hotwired/turbo-rails'
Turbo.session.drive = false

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
